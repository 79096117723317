<template>
  <!-- 选择图片组件 -->
  <div class="choiceImage">
    <!-- 此处图片必须为已审核 -->
    <el-popover
      placement="right"
      trigger="hover"
      :disabled="!viewUrl"
    >
      <img style="width: 200px" :src="viewUrl">
      <el-button slot="reference" :type="type" :size="size" @click="dialog = true">
        {{ text }}
      </el-button>
    </el-popover>

    <el-dialog :visible.sync="dialog" :close-on-click-modal="false" width="60%" append-to-body>
      <div class="dialog-content">
        <!-- <div class="step1">
          <el-button size="mini" :plain="choiceType !== 1" @click="choiceType = 1" type="primary">官方图库</el-button>
          <el-button size="mini" :plain="choiceType !== 2" @click="choiceType = 2" type="primary">我的图库</el-button>
        </div> -->
        <div class="step2">
          <el-select v-model="model.isAudit" style="width: 100px;" size="mini" placeholder="审核状态">
            <el-option
              v-for="(option, oi) in stateList"
              :key="oi"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
          <el-select v-model="model.pictureTypeId" style="width: 100px; margin-left: 10px" size="mini" placeholder="类别">
            <el-option label="类型" :value="'0'"></el-option>
            <el-option
              v-for="(option, oi) in list4"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>
          <el-select v-model="model.prictureSexId" style="width: 100px; margin-left: 10px" size="mini" placeholder="性别">
            <el-option label="性别" :value="'0'"></el-option>
            <el-option
              v-for="(option, oi) in list3"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>
          <el-select v-model="model.prictureAgeId" style="width: 100px; margin-left: 10px" size="mini" placeholder="年龄">
            <el-option label="年龄" :value="'0'"></el-option>
            <el-option
              v-for="(option, oi) in list2"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>
          <el-select v-model="model.prictureStyleId" style="width: 100px; margin-left: 10px" size="mini" placeholder="时空">
            <el-option label="时空" :value="'0'"></el-option>
            <el-option
              v-for="(option, oi) in list1"
              :key="oi"
              :label="option.dictLabel"
              :value="option.dictValue"
            ></el-option>
          </el-select>
          <el-input v-model="model.content" size="mini" style="margin-left: 10px; width: 200px;" placeholder="搜索关键词"></el-input>
        </div>

        <div class="step3">
          <div class="item" v-for="(item, i) in pictureList" :key="i" @click="choiceImage(item)" @dblclick="pushImage(item)">
            <div class="item-image">
              <!-- 底图 -->
              <img
                style="width: 100%; height: 100%; border-radius: 4px;"
                :src="item.picturePath"
              >
              <!-- 选中状态-右上角 -->
              <div class="right" v-if="imgModel && imgModel.id === item.id">
                <img src="@/assets/check.png" style="width: 30px; height:30px;"/>
              </div>
            </div>
            <div class="item-message">
              <div class="row1">
                {{ item.content || '未命名' }}
              </div>
              <div class="row2">
                <span>{{ pictureTypeName(item.pictureTypeId) }}</span>
                <span style="color: rgba(153, 153, 153, 1)">{{timestampToDate(item.createTime)}}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="step4">
          <el-pagination
            :total="total"
            :current-page.sync="model.page"
            :page-size.sync="model.limit"
            layout="prev, pager, next"
          ></el-pagination>
        </div>

        <div class="step4">
          <el-button
            @click="surePush"
            style="width: 80px; border:1px solid rgba(250, 225, 238, 1); background: rgba(250, 225, 238, 1); color: #333"
            type="warning"
          >确定</el-button>
        </div>
      </div>
    </el-dialog>

  </div>
</template>

<script>
export default {
  props: {
    viewUrl: {
      default: ''
    },
    type: {
      default: 'primary'
    },
    text: {
      default: '选择图片'
    },
    size: {
      default: 'mini'
    }
  },
  data () {
    return {
      dialog: false,
      choiceType: 1, // 1官方图库， 2我的图库
      model: {
        content: '',
        pictureTypeId: '0',
        prictureSexId: '0',
        prictureAgeId: '0',
        prictureStyleId: '0',
        isAudit: '0',
        page: 1,
        limit: 20
      },
      total: 0,
      stateList: [
        {
          id: '0',
          name: '审核状态'
        },
        {
          id: '1',
          name: '草稿箱',
          color: 'rgba(215, 213, 223, 1)'
        },
        {
          id: '2',
          name: '已审核',
          color: 'rgba(139, 209, 203, 1)'
        },
        {
          id: '3',
          name: '未通过',
          color: 'rgba(248, 205, 227, 1)'
        },
      ],
      pictureList: [],
      imgModel: {
        id: '',
        picturePath: ''
      }
    }
  },
  created () {
    
    // this.searchPicture()
  },
  watch: {
    model: {
      handler () {
        this.searchPicture()
      },
      deep: true
    },
    dialog(v) {
      if(v) {
         this.searchPicture()
      }
    }
  },
  computed: {
    list1 () {
      return this.$store.state.dict.list1
    },
    list2 () {
      return this.$store.state.dict.list2
    },
    list3 () {
      return this.$store.state.dict.list3
    },
    list4 () {
      return this.$store.state.dict.list4
    }
  },
  methods: {
    showDialog() {
      this.dialog = true
    },
    choiceImage (imgModel) {
      if (imgModel === this.imgModel) {
        this.imgModel = null
      } else {
        this.imgModel = imgModel
      }
    },
    pictureTypeName (id) {
      let re = ''
      this.list4.forEach(element => {
        if (element.dictValue == id) {
          re =  element.dictLabel
        }
      })
      return re
    },
    async searchPicture () {
      const res = await this.getRequest.searchPicture(this.model)
      this.pictureList = res.data
      this.total = res.count
    },
    pushImage (imgModel) {
      // 双击直接push
      this.choiceImage(imgModel)
      this.surePush()
    },
    surePush () {
      this.$emit('surePush', {
        id: this.imgModel.id,
        picturePath: this.imgModel.picturePath
      })
      this.dialog = false
    }
  }
}
</script>

<style lang='scss' scoped>
.dialog-content {
  .step1 {
    display: flex;
    justify-content: center;
  }
  .step2 {
    display: flex;
    margin-top: 25px;
    margin-left: 9px;
  }
  .step3 {
    height: 45vh;
    overflow-y: scroll;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    .item {
      margin-bottom: 40px;
      margin-right: 20px;
      width: 198px;
      cursor: pointer;
      .item-image {
        height: 148px;
        background: rgba(236,234,250,1);
        border-radius: 4px;
        position: relative;
        .right {
          position: absolute;
          top: 0px;
          right: 0;
        }
      }
      .item-message {
        font-size: 14px;
        .row1 {
          margin-top: 10px;
        }
        .row2 {
          margin-top: 10px;
          display: flex;
          justify-content: space-between;
        }
      }
    }
  }
  .step4 {
    margin-top: 20px;
    display: flex;
    justify-content: center;
  }
}
</style>
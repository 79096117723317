<template>
  <div class="h-100">
    <div class="flex-cloumn h-100">
      <div class="tab">
        <!-- tab栏 -->
        <div
          class="tab-item"
          v-for="item in tabMenu"
          :key="item.id"
          :class="curTab === item.id ? 'tab-item-active' : ''"
          @click="curTab = item.id"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="row2 flex-1 p-r" v-loading="loading">
        <el-card class="row2-card">
          <div class="transfer-list">
            <transfer-item
              v-for="(item, index) in transferPages"
              :key="item.id"
              :item="item"
              :index="index"
              :page="tableFilter1.currPage"
              @delTransferPage="delTransferPage(item.id, index)"
              @changeItem="(form) => changeItem(form, index)"
            />
            <el-button size="mini" @click="addTransferItem">添加</el-button>
          </div>
        </el-card>
        <el-button
          @click="saveTransferPages"
          style="
            position: absolute;
            bottom: 30px;
            right: 30px;
            display: flex;
            justify-content: flex-end;
            margin-top: 10px;
            border: 1px solid rgba(250, 225, 238, 1);
            background: rgba(250, 225, 238, 1);
            color: #333;
            font-weight: bold;
          "
          >保存</el-button
        >
      </div>
    </div>
    <div class="row3">
      <el-pagination
        :total="total"
        :page-size="tableFilter1.limit"
        :current-page="tableFilter1.currPage"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import TransferItem from "./common/components/transferItem.vue";
import { formRule, checkRepeatKey } from "@tools";
export default {
  components: {
    TransferItem,
  },
  data() {
    this.formRule = [
      {
        label: "name",
        name: "素材名称",
        rule: {
          required: true,
        },
      },
      {
        label: "freeButtonId",
        name: "免费按钮素材",
        rule: {
          required: true,
        },
      },
      {
        label: "paidButtonId",
        name: "付费按钮素材",
        rule: {
          required: true,
        },
      },
    ];
    return {
      loading: false,
      // tab栏
      curTab: 1,
      tabMenu: [
        { id: 1, name: "营销落地页" },
        // { id: 2, name: "优惠领取页" },
        // { id: 3, name: "微信上车页" },
      ],
      tableFilter1: {
        currPage: 1,
        limit: 5,
      },
      transferPages: [
        {
          name: "默认素材",
          bottomId: "",
          freeButtonId: "",
          freePosition: 0,
          paidButtonId: "",
          paidPosition: 0,
          bottomUrl: "",
          freeButtonUrl: "",
          paidButtonUrl: "",
          defaultFlag: 1,
        },
      ],
      total: 0
    };
  },
  async created() {
    this.getTransferPages();
    this.index = 123
  },
  methods: {
    async getTransferPages() {
      const data = await this.$api.market.getTransferPageByPage(
        this.tableFilter1
      );
      if (data.defaultMarketingTransfer) {
        const { data: datas, defaultMarketingTransfer, count } = data
        this.transferPages = datas;
        if (this.tableFilter1.currPage === 1) {
          this.transferPages.unshift(defaultMarketingTransfer)
        }
        this.total = count
      }
    },
    changeItem(form, index) {
      this.transferPages[index] = form;
    },
    async saveTransferPages() {
      if (this.transferPages.some((item) => !formRule(item, this.formRule)))
        return;
      if (!checkRepeatKey(this.transferPages, 'name')) return
      this.loading = true
      await this.$api.market.editTransferPage(this.transferPages);
      this.loading = false
      this.$message.success("营销落地页列表编辑成功");
      this.getTransferPages();
    },
    async delTransferPage(id, index) {
      if (id) {
        await this.$api.market.delTransferPage(id);
      }
      this.transferPages.splice(index, 1);
    },
    addTransferItem() {
      this.transferPages.push({
        name: "",
        bottomId: "",
        freeButtonId: "",
        freePosition: 0,
        paidButtonId: "",
        paidPosition: 0,
        bottomUrl: "",
        freeButtonUrl: "",
        paidButtonUrl: "",
        defaultFlag: 0,
      });
    },
    handleCurrentChange(page) {
      this.tableFilter1.currPage = page;
      this.getTransferPages();
    },
  },
  watch: {},
  computed: {},
};
</script>
<style lang="scss" scoped>
@import "./common/style/transfer.scss";
@import "./common/style/form.scss";
</style>
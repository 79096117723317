<template>
  <div class="flex-col flex-as m-b-40 p-r">
    <el-button
      size="mini"
      type="danger"
      v-if="!form.defaultFlag"
      @click="delTransferPage"
      plain
      class="del-btn"
      >删除设置</el-button
    >
    <div v-if="showDefault" class="title text-center m-b-30">默认素材</div>
    <div class="flex-row flex-ac m-b-10">
      <div class="computed-title">
        素材名称：<span style="color: red; font-size: 16px" v-if="!showDefault">*</span>
      </div>
      <div style="font-size: 15px" v-if="form.defaultFlag">{{ form.name }}</div>
      <el-input v-else style="width: 170px" size="mini" v-model="form.name">
      </el-input>
    </div>
    <div class="flex-row flex-as m-b-10">
      <div class="computed-title">底图：</div>
      <div class="uploader pointer" @click="openChoiceImage">
        <img :src="form.bottomUrl" v-if="form.bottomUrl" alt="" />
      </div>
    </div>
    <div class="flex-row flex-ac m-b-10">
      <div class="computed-title">
        免费按钮：<span style="color: red; font-size: 16px">*</span>
      </div>

      <choice-image
        :viewUrl="form.freeButtonUrl"
        class="m-r-10"
        @surePush="(v) => sureChoiceImage(v, 'freeButton')"
        ref="choiceImage"
      />
      <el-button-group style="overflow: hidden; border-radius: 10px">
        <el-button
          round
          @click="form.freePosition = 0"
          :type="form.freePosition === 0 ? 'primary' : ''"
          size="mini"
          >底部</el-button
        >
        <el-button
          round
          @click="form.freePosition = 1"
          :type="form.freePosition === 1 ? 'primary' : ''"
          size="mini"
          >居中</el-button
        >
      </el-button-group>
    </div>
    <div class="flex-row flex-ac">
      <div class="computed-title">
        付费按钮：<span style="color: red; font-size: 16px">*</span>
      </div>
      <choice-image
        :viewUrl="form.paidButtonUrl"
        class="m-r-10"
        @surePush="(v) => sureChoiceImage(v, 'paidButton')"
        ref="choiceImage"
      />
      <el-button-group style="overflow: hidden; border-radius: 10px">
        <el-button
          round
          @click="form.paidPosition = 0"
          :type="form.paidPosition === 0 ? 'primary' : ''"
          size="mini"
          >底部</el-button
        >
        <el-button
          round
          @click="form.paidPosition = 1"
          :type="form.paidPosition === 1 ? 'primary' : ''"
          size="mini"
          >居中</el-button
        >
      </el-button-group>
    </div>
    <choice-image
      v-show="false"
      @surePush="(v) => sureChoiceImage(v, 'bottom')"
      ref="choiceImage"
    />
    <div v-if="showDefault" class="title text-center m-t-20">特殊素材</div>
  </div>
</template>

<script>
import choiceImage from "@/components/common/choiceImage";
export default {
  components: {
    choiceImage,
  },
  props: {
    item: {
      default: () => {},
    },
    index: Number,
    page: Number
  },
  created() {
    this.form = this.item;
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    showDefault () {
      return this.index === 0 && this.page === 1
    }
  },
  watch: {
    form: {
      deep: true,
      handler(newVal) {
        this.$emit("changeItem", newVal);
      },
    },
  },
  methods: {
    sureChoiceImage(v, type) {
      this.form[`${type}Url`] = v.picturePath;
      this.form[`${type}Id`] = v.id;
    },
    openChoiceImage() {
      this.$refs.choiceImage.showDialog();
    },
    delTransferPage() {
      this.$confirm("此操作将永久删除该营销落地页, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$emit("delTransferPage");
      });
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../style/form.scss";
.uploader {
  width: 178px;
  height: 178px;
  border: 1px dashed #d9d9d9;

  img {
    width: 100%;
    height: 100%;
  }
}
.del-btn {
  position: absolute;
  right: 0;
  top: 0;
}
</style>